.login-block {
    text-align: center;
}

.login-logo {
    @include v-margin($gutter-half);
    @include h-margin(auto);

    @include bp('xs') {
        @include v-margin($gutter);
    }

    @include bp('sm') {
        @include v-margin($gutter-double);
    }

    transform: translateX(-6%);
    max-width: 50%;
}

.form--login {
    max-width: 40rem;
    @include h-margin(auto);
    margin-bottom: $gutter;
    text-align: left;
}