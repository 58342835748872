/* VIEW */

// VIEW

@mixin view-horizontal-padding() {
    @include h-padding($gutter-half);
    
    @include bp($breakpoint-sidebar-compact) {
        @include h-padding($gutter);
    }    
    
    // @include bp($breakpoint-sidebar-full) {
    //     @include h-padding($gutter-and-half);
    // }    
}

.view {}    

.view-header,
.view-footer {
    &:empty {
        display: none;
    }
}

.view-body {}

.view-header {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    
    .has-default-layout & {
        border-bottom: 1px solid $color-gray-lighter;
    }
}

.view-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color-gray-lighter;
    @include v-padding($gutter-half);
    @include view-horizontal-padding();

    @include bp('sm') {
        @include v-padding($gutter-quarter * 3);
    }
}

// VIEW HEADER

.view-header {
    .has-default-layout & {
        @include bp($breakpoint-view-header-inline) {
            display: flex;
            align-items: center;
        }
    }
}

.view-header--protocol-details,
.view-header--protocol-versions,
.view-header--protocol-sharing,
.view-header--protocol-grouping,
.view-header--protocol-files {
    padding-bottom: $gutter;

    &,
    .pagination-counter,
    .pagination-counter > *,
    .view-header__footer {
        color: white;
    }
}

.view-header--protocol-details { background-color: $color-protocol-details; }
.view-header--protocol-versions { background-color: $color-protocol-versions; }
.view-header--protocol-sharing { background-color: $color-protocol-sharing; }
.view-header--protocol-files { background-color: $color-protocol-files; }
.view-header--protocol-grouping { background-color: $color-protocol-grouping; }

.view-header__header,
.view-header__footer {
    display: flex;
    align-items: center;
    @include v-padding($gutter-quarter);
    min-height: 5.8rem;
    
    .has-default-layout & {
        @include view-horizontal-padding();
    }

    &:empty {
        display: none;
    }
}

.view-header__header {
    flex-shrink: 0;
    justify-content: space-between;

    @include bp($breakpoint-view-header-inline) {
        order: 100;
    }
}

.view-header__footer {
    flex-grow: 1;
    color: $color-primary;
}

// View header elements

.back-btn {
    flex-shrink: 0;
    margin-left: - $gutter-half;
    margin-right: $gutter-quarter;
}

.page-title {
    margin-bottom: 0;
    line-height: $line-height-small;

    > span {
        text-transform: uppercase;
    }

    .view-header--protocol-details & {
        font-weight: $font-weight-medium;
        text-transform: uppercase;
    }

    .has-adventice-layout & {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        color: $body-color-base;
    }
}

.page-subtitle {
    display: block;
    margin-top: $gutter-quarter;
    font-size: $font-size-big;
    font-weight: $font-weight-normal;
    text-transform: initial;
}

// View footer elements

.view-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: $gutter-half $gutter;

    .btn {
        min-width: 8rem;
    }
}

.view-actions--left {
    justify-content: left;
}

