/*******************************************************************************
 * FORMS
 * Path: lib/components/_forms.scss
 ******************************************************************************/

input,
textarea,
select {
    transition: all 0.25s;
    // user-select: text; // Fix for Safari
    // -webkit-user-select: text; // Fix for Safari

    &:focus,
    &:focus + .input-after {
        outline: none;
        @include shadow(1, $color-gray-dark);
    }
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
    transition: all 0.25s;
}

// input[type="checkbox"]:focus + label::before,
// input[type="radio"]:focus + label::before {
//     outline: none;
//     @include shadow(2, $input-color-focus);
// }

button:focus {
    outline: none;
}

// BASE INPUTS

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="date"],
input[type="time"],
input[type="month"],
input[type="search"],
select,
textarea {
    display: block;
    width: 100%;
    background-color: $input-bg;
    color: $input-color;
    font-size: $font-size-big;

    .input-block--strong-focus & {
        background-color: $input-bg-strong-focus;

        &:focus {
            background-color: $input-bg;
        }
    }
}

// Same height inputs
input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], input[type="month"], input[type="time"], input[type="search"],
select,
option,
.input-before/*:not(.btn):not(a)*/,
.input-after/*:not(.btn):not(a)*/ {
    padding: 1.2rem; // Unified padding, like buttons
    height: $input-height-base; // Uniformize height. Must match the button / icon height
    // line-height: 1.4; // Magic value - optical fine-tuning
}

// Border
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="search"],
select,
textarea,
.multiselect__tags {
    border: none;
    border-color: $input-border-color;
    border-style: solid;
    border-width: 0;

    // border-top-width: 1px;
    // border-bottom-width: 1px;
    border-width: 1px;

    // &:first-child {
    //     border-left-width: 1px;
    // }

    // &:last-child {
    //     border-right-width: 1px;
    // }

    &:hover,
    &:hover + .input-after {
        border-color: $input-border-color-hover;
    }

    .input-block--success &,
    .input-block--success & + .input-after {
        border-color: $color-success;
    }

	.input-block--error &:not(:placeholder-shown),
    .input-block--error &,
	.input-block--error & + .input-after{
        border-color: $color-error;
    }
}

// Border when filled
// TODO: JS for date, time and select
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea {

    &:not(:placeholder-shown),
    &:not(:placeholder-shown) + .input-after {
        border-color: $input-border-color-filled;
    }
}

// Border radius
input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], input[type="month"], input[type="time"], input[type="search"],
textarea,
.input-before/*:not(.btn):not(a)*/,
.input-after/*:not(.btn):not(a)*/ {
    border-radius: 0;

    &:first-child {
        border-top-left-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
    }

    &:last-child {
        border-top-right-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
    }
}

// Specific inputs

input[type="number"] {
    text-align: right;
}

textarea {
    padding: 1.2rem; // Unified padding, like buttons
    line-height: $line-height-base;
    resize: vertical;
}

select {
    background-color: $input-bg;
    @include v-padding(0.8rem); // Smaller padding, avoid cropping
    border-radius: $input-border-radius;

    &[multiple] {
        height: auto;
        @include h-padding(0);
    }
}

// Label

label,
.label {
    display: block;
    margin-bottom: $gutter-quarter;
    font-size: $font-size-small;
    line-height: $line-height-small; // Compact for multiline

    .input-block > & {
        font-weight: $font-weight-semibold;
    }

    .input-block.input-block--error > & {
        color: $color-error;
    }

    .input-block.input-block--required > & {
        &::after {
            display: inline-block;
            margin-left: $gutter-eighth / 2;
            content: "*";
            color: $color-error;
            transform: translateY(-$gutter-eighth);
        }
    }
}

// Fieldset and legend
/* From http://thatemil.com/blog/2015/01/03/reset-your-fieldset/ by Emil Björklund */

legend {
    display: table;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0.01em 0 0 0;
    padding-bottom: $gutter-half;
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

fieldset {
    // @include h-margin(- $gutter-half);
    // margin-bottom: $gutter;
    // @include h-padding($gutter-half);
    // border: 1px solid $color-gray-lightest;
}

legend {
    // @include h(4);
    // Label-like styling
    margin-bottom: $gutter-half;
    font-size: $font-size-small;
    line-height: $line-height-small; // Compact for multiline
    font-weight: $font-weight-semibold;
}

// Placeholder
// See: https://css-tricks.com/almanac/selectors/p/placeholder/

@mixin placeholder() {
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @content;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        @content;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        @content;
    }
}

@include placeholder() {
    font-family: sans-serif;
    font-style: italic;
}

// Submit
// See: Btn component

// LAYOUT

// Form layout

.form {
    align-items: flex-start; // Better when unknown content
    // align-items: flex-end; // Better when multiline label AND same height inputs
}

@mixin form-inline($bp: "sm") {
    > .input-block {
        &:not(:last-child) {
            margin-bottom: $gutter-half;
        }
    }

    @include bp($bp) {
        display: flex;
        align-items: flex-end;

        > .input-block {
            &:not(:last-child) {
                margin-bottom: 0;
                margin-right: $input-width-gutter-block-inline;
            }

            &.input-block--inline {
                label,
                .label,
                .input {
                    flex-basis: auto;
                }
            }
        }

        > .btn {
            &:not(:last-child) {
                margin-right: $input-width-gutter-btn-inline;
            }
        }
    }
}

// Loop on breakpoints
@each $breakpoint-name, $breakpoint-width in $breakpoints {
    .form--inline-#{$breakpoint-name} {
        @include form-inline($breakpoint-name);
    }
}

// Block layout

.input-block {
    &:not(:last-child) {
        margin-bottom: $gutter;
    }

    > input[type="submit"],
    > button[type="submit"] {
        flex-basis: auto;
    }

    &.input-block--inline {
        @include bp("sm") {
            // Not on mobile
            // flex-wrap: nowrap; // Breaks input info text
            display: flex;

            > label,
            > .label {
                flex-basis: calc(50% - #{$input-width-gutter-label-inline});
                margin-right: $input-width-gutter-label-inline;
                align-self: center;
                margin-bottom: 0;
            }

            > .input {
                flex-basis: 50%;
                align-self: center;
            }
        }
    }

    &.input-block--error {
    }
    &.input-block--success {
    }
    &.input-block--required {
    }
    &.input-block--disabled {
        opacity: 0.5;
        cursor: not-allowed;

        input {
            cursor: not-allowed;
        }
    }
    &.input-block--readonly {       
        cursor: not-allowed;
        input {
            cursor: not-allowed;
        } 
    }
    &.input-block--readonly.input-block--disabled {       
        opacity: 1;
        cursor: not-allowed;
        input {
            cursor: not-allowed;
        } 
    }

    .key-value-value & {
        max-width: 38rem;
    }
}

// Input wrapper (required?)

.input {
    display: flex;

    &.input--inline {}

    &.input--block {
        display: block;
    }
}

// Radio buttons and checkboxes

.checkbox,
.radio {
    position: relative;

    // Block radiobox
    display: flex;
    align-items: center;

    input[type="checkbox"],
    input[type="radio"] {
        
        // Visually hide
        position: absolute;
        @include size($input-size-radiobox);
        opacity: 0;
    }
    
    label,
    .label {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 1rem 0;
        font-size: $font-size-base;
    }

    // Inline radiobox
    .input--inline & {
        align-items: center;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: $input-width-gutter-label-inline;
        }
    }

    &.radio--disabled,
    &.checkbox--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.checkbox {
    label,
    .label {
        &::before,
        &::after {
            content: "";
            display: block;
            transition: all 0.25s;
            flex-shrink: 0;
        }

        // Outer
        &::before {
            @include size($input-size-radiobox);
            border: 1px solid $input-border-color;
            border-color: $color-primary-lighty; // Override
            border-radius: 2px;
        }

        // Inner
        &::after {
            order: -1;
            position: absolute;
            left: (($input-size-radiobox - $input-size-radiobox-inner) * 1.25);
            // top: (($input-size-radiobox - $input-size-radiobox-inner) * 3 / 4);
            @include size($input-size-radiobox-inner * 2 / 3, $input-size-radiobox-inner);
            background-color: transparent;
            border-bottom: 3px solid white;
            border-right: 3px solid white;
            transform-origin: 50% 0%;
            transform: rotateZ(45deg) scale(0);
        }

        > span {
            display: block;

            &:not(:empty) {
                margin-left: $input-width-gutter-label-radiobox;
            }
        }
    }

    input:checked {
        & + label,
        & + .label {
            // Outer
            &::before {
                background-color: $color-primary-lighty;
            }
            // Inner
            &::after {
                transform: rotateZ(45deg) scale(1);
            }
        }
    }
}

.radio {
    label,
    .label {
        &::before,
        &::after {
            content: "";
            display: block;
            transition: all 0.25s;
            flex-shrink: 0;
        }

        // Outer
        &::before {
            @include size($input-size-radiobox);
            border: 1px solid $input-border-color;
            border-color: $color-primary; // Override
        }

        // Inner
        &::after {
            order: -1;
            position: absolute;
            left: (($input-size-radiobox - $input-size-radiobox-inner) / 2);
            @include size($input-size-radiobox-inner);
            background-color: $input-color-radiobox-inner;
            transform: scale(0);
        }

        > span {
            display: block;
            margin-left: $input-width-gutter-label-radiobox;
        }

        &::before,
        &::after {
            border-radius: 50%;
        }
    }

    input:checked {
        & + label,
        & + .label {
            // Inner
            &::after {
                transform: scale(1);
            }
        }
    }
}

// Input prefix and suffix

.input-before,
.input-after {
    flex-shrink: 0;
    transition: all 0.25s;

    &:not(.btn):not(a) {
        white-space: nowrap;
        // background-color: $input-border-color;
        border: 1px solid $input-border-color;
        display: flex;
        align-items: center;
    }

    .icon {
        @include h-margin(- $gutter-eighth);
    }
}

// Input text infos / errors

.input-text {
    @include v-padding($gutter-eighth);
    text-align: right;
    font-size: $font-size-small;
    line-height: $line-height-small;
    font-style: italic;

    &.input-text--info {
        color: $color-gray;
    }
    &.input-text--error {
        color: $color-error;
    }
    &.input-text--success {
        color: $color-success;
    }
}
