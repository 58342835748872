/*******************************************************************************
 * GRID
 * Path: lib/layout/_grid.scss
 ******************************************************************************/

// CSS GRID
// See: https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/

.grid {
    display: grid;
    gap: $gutter;
    grid-template-columns: minmax(0, 1fr);

    @include bp('xs') {
        grid-template-columns: repeat( auto-fill, minmax(28rem, 1fr) );
    }
}

.grid--compact {
    @include bp('xs') {
        grid-template-columns: repeat( auto-fill, minmax(16rem, 1fr) );
    }
}

.col--fullwidth {
    grid-column: 1 / -1;
}

.col--span-2 {
    grid-column: 1 / span 2;
}

.col--span-3 {
    grid-column: 1 / span 3;
}

// .grid--col-xs-6 {
//     @include bp('xs') {
//         grid-template-columns: repeat( 2, minmax(0, 1fr) );
//     }

//     @include bp('md') {
//         // justify-content: center;
//         grid-template-columns: repeat( 2, 28rem );
//     }
// }

.col-order-first {
    @include bp('sm') {
        order: -100;
    }
}