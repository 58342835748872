/*******************************************************************************
 * COLORS
 * Path: lib/base/_colors.scss
 ******************************************************************************/

////////////////////////////////////////////////////////////////////////////////
// COLOR SHADES COMPUTATION

// Computed "automatic" color shades, and gray scale

// COLOR VARIABLES

// $color-primary-darkest: mix(black, $color-primary, $color-variation-ratio-most);
// $color-primary-darker: mix(black, $color-primary, $color-variation-ratio-more);
// $color-primary-dark: mix(black, $color-primary, $color-variation-ratio-base);
// $color-primary-darky: mix(black, $color-primary, $color-variation-ratio-less);
// $color-primary-lighty: mix(white, $color-primary, $color-variation-ratio-less);
// $color-primary-light: mix(white, $color-primary, $color-variation-ratio-base);
// $color-primary-lighter: mix(white, $color-primary, $color-variation-ratio-more);
// $color-primary-lightest: mix(white, $color-primary, $color-variation-ratio-most);

$color-secondary-darkest: mix(black, $color-secondary, $color-variation-ratio-most);
$color-secondary-darker: mix(black, $color-secondary, $color-variation-ratio-more);
$color-secondary-dark: mix(black, $color-secondary, $color-variation-ratio-base);
$color-secondary-darky: mix(black, $color-secondary, $color-variation-ratio-less);
$color-secondary-lighty: mix(white, $color-secondary, $color-variation-ratio-less);
$color-secondary-light: mix(white, $color-secondary, $color-variation-ratio-base);
$color-secondary-lighter: mix(white, $color-secondary, $color-variation-ratio-more);
$color-secondary-lightest: mix(white, $color-secondary,$color-variation-ratio-most);

$color-accent-darkest: mix(black, $color-accent, $color-variation-ratio-most);
$color-accent-darker: mix(black, $color-accent, $color-variation-ratio-more);
$color-accent-dark: mix(black, $color-accent, $color-variation-ratio-base);
$color-accent-darky: mix(black, $color-accent, $color-variation-ratio-less);
$color-accent-lighty: mix(white, $color-accent, $color-variation-ratio-less);
$color-accent-light: mix(white, $color-accent, $color-variation-ratio-base);
$color-accent-lighter: mix(white, $color-accent, $color-variation-ratio-more);
$color-accent-lightest: mix(white, $color-accent, $color-variation-ratio-most);

// $color-gray-darkest: mix(black, $color-gray, $color-variation-ratio-most);
// $color-gray-darker: mix(black, $color-gray, $color-variation-ratio-more);
// $color-gray-dark: mix(black, $color-gray, $color-variation-ratio-base);
// $color-gray-darky: mix(black, $color-gray, $color-variation-ratio-less);
// $color-gray-lighty: mix(white, $color-gray, $color-variation-ratio-less);
// $color-gray-light: mix(white, $color-gray, $color-variation-ratio-base);
// $color-gray-lighter: mix(white, $color-gray, $color-variation-ratio-more);
// $color-gray-lightest: mix(white, $color-gray, $color-variation-ratio-most);

// COLOR HELPER CLASSES

@if $generate_classes and $generate-color-classes {
	
	// COLOR CLASSES

	.color-primary-darkest { color: $color-primary-darkest; }
	.color-primary-darker { color: $color-primary-darker; }
	.color-primary-dark { color: $color-primary-dark; }
	.color-primary-darky { color: $color-primary-darky; }
	.color-primary { color: $color-primary; }
	.color-primary-lighty { color: $color-primary-lighty; }
	.color-primary-light { color: $color-primary-light; }
	.color-primary-lighter { color: $color-primary-lighter; }
	.color-primary-lightest { color: $color-primary-lightest; }

	.color-secondary-darkest { color: $color-secondary-darkest; }
	.color-secondary-darker { color: $color-secondary-darker; }
	.color-secondary-dark { color: $color-secondary-dark; }
	.color-secondary-darky { color: $color-secondary-darky; }
	.color-secondary { color: $color-secondary; }
	.color-secondary-lighty { color: $color-secondary-lighty; }
	.color-secondary-light { color: $color-secondary-light; }
	.color-secondary-lighter { color: $color-secondary-lighter; }
	.color-secondary-lightest { color: $color-secondary-lightest; }

	.color-accent-darkest { color: $color-accent-darkest; }
	.color-accent-darker { color: $color-accent-darker; }
	.color-accent-dark { color: $color-accent-dark; }
	.color-accent-darky { color: $color-accent-darky; }
	.color-accent { color: $color-accent; }
	.color-accent-lighty { color: $color-accent-lighty; }
	.color-accent-light { color: $color-accent-light; }
	.color-accent-lighter { color: $color-accent-lighter; }
	.color-accent-lightest { color: $color-accent-lightest; }

	.color-gray-darkest { color: $color-gray-darkest; }
	.color-gray-darker { color: $color-gray-darker; }
	.color-gray-dark { color: $color-gray-dark; }
	.color-gray-darky { color: $color-gray-darky; }
	.color-gray { color: $color-gray; }
	.color-gray-lighty { color: $color-gray-lighty; }
	.color-gray-light { color: $color-gray-light; }
	.color-gray-lighter { color: $color-gray-lighter; }
	.color-gray-lightest { color: $color-gray-lightest; }
	.color-gray-lightestest { color: $color-gray-lightestest; }

	.color-black { color: black; }
	.color-white { color: white; }

	.color-error { color: $color-error; }
	.color-warning { color: $color-warning; }
	.color-info { color: $color-info; }
	.color-success { color: $color-success; }

	// BACKGROUND COLOR CLASSES

	.bg-primary-darkest { background-color: $color-primary-darkest; }
	.bg-primary-darker { background-color: $color-primary-darker; }
	.bg-primary-dark { background-color: $color-primary-dark; }
	.bg-primary-darky { background-color: $color-primary-darky; }
	.bg-primary { background-color: $color-primary; }
	.bg-primary-lighty { background-color: $color-primary-lighty; }
	.bg-primary-light { background-color: $color-primary-light; }
	.bg-primary-lighter { background-color: $color-primary-lighter; }
	.bg-primary-lightest { background-color: $color-primary-lightest; }

	.bg-secondary-darkest { background-color: $color-secondary-darkest; }
	.bg-secondary-darker { background-color: $color-secondary-darker; }
	.bg-secondary-dark { background-color: $color-secondary-dark; }
	.bg-secondary-darky { background-color: $color-secondary-darky; }
	.bg-secondary { background-color: $color-secondary; }
	.bg-secondary-lighty { background-color: $color-secondary-lighty; }
	.bg-secondary-light { background-color: $color-secondary-light; }
	.bg-secondary-lighter { background-color: $color-secondary-lighter; }
	.bg-secondary-lightest { background-color: $color-secondary-lightest; }

	.bg-accent-darkest { background-color: $color-accent-darkest; }
	.bg-accent-darker { background-color: $color-accent-darker; }
	.bg-accent-dark { background-color: $color-accent-dark; }
	.bg-accent-darky { background-color: $color-accent-darky; }
	.bg-accent { background-color: $color-accent; }
	.bg-accent-lighty { background-color: $color-accent-lighty; }
	.bg-accent-light { background-color: $color-accent-light; }
	.bg-accent-lighter { background-color: $color-accent-lighter; }
	.bg-accent-lightest { background-color: $color-accent-lightest; }

	.bg-gray-darkest { background-color: $color-gray-darkest; }
	.bg-gray-darker { background-color: $color-gray-darker; }
	.bg-gray-dark { background-color: $color-gray-dark; }
	.bg-gray-darky { background-color: $color-gray-darky; }
	.bg-gray { background-color: $color-gray; }
	.bg-gray-lighty { background-color: $color-gray-lighty; }
	.bg-gray-light { background-color: $color-gray-light; }
	.bg-gray-lighter { background-color: $color-gray-lighter; }
	.bg-gray-lightest { background-color: $color-gray-lightest; }
	.bg-gray-lightestest { background-color: $color-gray-lightestest; }
	.bg-gray-blueish { background-color: $color-gray-blueish; }

	.bg-black { background-color: black; }
	.bg-white { background-color: white; }

	.bg-error { background-color: $color-error; }
	.bg-warning { background-color: $color-warning; }
	.bg-info { background-color: $color-info; }
	.bg-success { background-color: $color-success; }

	.bg-material-color-0{  background-color: $color-material-amber; }
	.bg-material-red, .bg-material-color-1 { background-color: $color-material-red; }
	.bg-material-pink, .bg-material-color-2 { background-color: $color-material-pink; }
	.bg-material-purple, .bg-material-color-3 { background-color: $color-material-purple; }
	.bg-material-deeppurple, .bg-material-color-4 { background-color: $color-material-deeppurple; }
	.bg-material-indigo, .bg-material-color-5 { background-color: $color-material-indigo; }
	.bg-material-blue, .bg-material-color-6 { background-color: $color-material-blue; }
	.bg-material-lightblue, .bg-material-color-7 { background-color: $color-material-lightblue; }
	.bg-material-cyan, .bg-material-color-8 { background-color: $color-material-cyan; }
	.bg-material-teal, .bg-material-color-9 { background-color: $color-material-teal; }
	.bg-material-green, .bg-material-color-10 { background-color: $color-material-green; }
	.bg-material-lightgreen, .bg-material-color-11 { background-color: $color-material-lightgreen; }
	.bg-material-lime, .bg-material-color-12 { background-color: $color-material-lime; }
	.bg-material-yellow, .bg-material-color-13 { background-color: $color-material-yellow; }
	.bg-material-amber, .bg-material-color-14 { background-color: $color-material-amber; }
	.bg-material-orange, .bg-material-color-15 { background-color: $color-material-orange; }
	.bg-material-deeporange, .bg-material-color-16 { background-color: $color-material-deeporange; }
	.bg-material-brown, .bg-material-color-17 { background-color: $color-material-brown; }
	.bg-material-grey, .bg-material-color-18 { background-color: $color-material-grey; }
	.bg-material-bluegrey, .bg-material-color-19 { background-color: $color-material-bluegrey; }
	

	.bg-micro-parcel-background-0 { background-color: $color-micro-parcel-background-0 !important; }
	.bg-micro-parcel-background-1 { background-color: $color-micro-parcel-background-1 !important; }
	.bg-micro-parcel-background-2 { background-color: $color-micro-parcel-background-2 !important; }
	.bg-micro-parcel-background-3 { background-color: $color-micro-parcel-background-3 !important; }
	.bg-micro-parcel-background-4 { background-color: $color-micro-parcel-background-4 !important; }
	.bg-micro-parcel-background-5 { background-color: $color-micro-parcel-background-5 !important; }
	.bg-micro-parcel-background-6 { background-color: $color-micro-parcel-background-6 !important; }
	.bg-micro-parcel-background-7 { background-color: $color-micro-parcel-background-7 !important; }
	.bg-micro-parcel-background-8 { background-color: $color-micro-parcel-background-8 !important; }
	.bg-micro-parcel-background-9 { background-color: $color-micro-parcel-background-9 !important; }
	.bg-micro-parcel-background-10 { background-color: $color-micro-parcel-background-10 !important; }
	.bg-micro-parcel-background-11 { background-color: $color-micro-parcel-background-11 !important; }
	.bg-micro-parcel-background-12 { background-color: $color-micro-parcel-background-12 !important; }
	.bg-micro-parcel-background-13 { background-color: $color-micro-parcel-background-13 !important; }
	.bg-micro-parcel-background-14 { background-color: $color-micro-parcel-background-14 !important; }
	.bg-micro-parcel-background-15 { background-color: $color-micro-parcel-background-15 !important; }
	.bg-micro-parcel-background-16 { background-color: $color-micro-parcel-background-16 !important; }
	.bg-micro-parcel-background-17 { background-color: $color-micro-parcel-background-17 !important; }
	.bg-micro-parcel-background-18 { background-color: $color-micro-parcel-background-18 !important; }
	.bg-micro-parcel-background-19 { background-color: $color-micro-parcel-background-19 !important; }
	.bg-micro-parcel-background-20 { background-color: $color-micro-parcel-background-20 !important; }
	.bg-micro-parcel-background-21 { background-color: $color-micro-parcel-background-21 !important; }
	.bg-micro-parcel-background-22 { background-color: $color-micro-parcel-background-22 !important; }
	.bg-micro-parcel-background-23 { background-color: $color-micro-parcel-background-23 !important; }
	.bg-micro-parcel-background-24 { background-color: $color-micro-parcel-background-24 !important; }
	.bg-micro-parcel-background-25 { background-color: $color-micro-parcel-background-25 !important; }
	.bg-micro-parcel-background-26 { background-color: $color-micro-parcel-background-26 !important; }
	.bg-micro-parcel-background-27 { background-color: $color-micro-parcel-background-27 !important; }
	.bg-micro-parcel-background-28 { background-color: $color-micro-parcel-background-28 !important; }
	.bg-micro-parcel-background-29 { background-color: $color-micro-parcel-background-29 !important; }
	.bg-micro-parcel-background-30 { background-color: $color-micro-parcel-background-30 !important; }
	.bg-micro-parcel-background-31 { background-color: $color-micro-parcel-background-31 !important; }
	.bg-micro-parcel-background-32 { background-color: $color-micro-parcel-background-32 !important; }
	.bg-micro-parcel-background-33 { background-color: $color-micro-parcel-background-33 !important; }
	.bg-micro-parcel-background-34 { background-color: $color-micro-parcel-background-34 !important; }

	.bg-micro-parcel-repetition-0 { background-color: $color-micro-parcel-repetition-0; }
	.bg-micro-parcel-repetition-1 { background-color: $color-micro-parcel-repetition-1; }
	.bg-micro-parcel-repetition-2 { background-color: $color-micro-parcel-repetition-2; }
	.bg-micro-parcel-repetition-3 { background-color: $color-micro-parcel-repetition-3; }
	.bg-micro-parcel-repetition-4 { background-color: $color-micro-parcel-repetition-4; }
	.bg-micro-parcel-repetition-5 { background-color: $color-micro-parcel-repetition-5; }
	.bg-micro-parcel-repetition-6 { background-color: $color-micro-parcel-repetition-6; }
	.bg-micro-parcel-repetition-7 { background-color: $color-micro-parcel-repetition-7; }
	.bg-micro-parcel-repetition-8 { background-color: $color-micro-parcel-repetition-8; }

	.color-micro-parcel-repetition-0 { color: $color-micro-parcel-repetition-0; }
	.color-micro-parcel-repetition-1 { color: $color-micro-parcel-repetition-1; }
	.color-micro-parcel-repetition-2 { color: $color-micro-parcel-repetition-2; }
	.color-micro-parcel-repetition-3 { color: $color-micro-parcel-repetition-3; }
	.color-micro-parcel-repetition-4 { color: $color-micro-parcel-repetition-4; }
	.color-micro-parcel-repetition-5 { color: $color-micro-parcel-repetition-5; }
	.color-micro-parcel-repetition-6 { color: $color-micro-parcel-repetition-6; }
	.color-micro-parcel-repetition-7 { color: $color-micro-parcel-repetition-7; }
	.color-micro-parcel-repetition-8 { color: $color-micro-parcel-repetition-8; }

	.bg-notation-in-progress { background-color: $color-notation-in-progress; }
	.bg-notation-in-progress-light { background-color: $color-notation-in-progress-light; }
	.bg-notation-wait-synchro { background-color: $color-notation-wait-synchro; }
	.bg-notation-wait-synchro-light { background-color: $color-notation-wait-synchro-light; }
	.bg-notation-error-synchro { background-color: $color-notation-error-synchro; }
	.bg-notation-error-synchro-light { background-color: $color-notation-error-synchro-light; }
}
