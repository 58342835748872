/*******************************************************************************
 *******************************************************************************
 *
 * DFS - DIATEM FRONT-END SKELETON
 *
 *******************************************************************************
 ******************************************************************************/

////////////////////////////////////////////////////////////////////////////////
// NORMALIZE CSS

@import "~normalize.css";

////////////////////////////////////////////////////////////////////////////////
// VENDOR LIBS

// // @import "~choices.js/public/assets/styles/choices.css";
// @import "~choices.js/src/styles/choices.scss";

// @import "~vue-code-highlight/themes/window.css";
@import "~vue-code-highlight/themes/prism-okaidia.css";

////////////////////////////////////////////////////////////////////////////////
// CONFIGURATION VARIABLES
//
// Note: variables definition need (and @import) the following core modules:
// * lib/base/colors
// * lib/base/gutter

@import "variables";

///////////////////////////////////////////////////////////////////////////////
// DFS MAIN LIBRARY

// BASE

@import "lib/base/shadows";
@import "lib/base/tools";
@import "lib/base/unit-tools";

// LAYOUT

@import "lib/layout/breakpoints";
@import "lib/layout/grid";
@import "lib/layout/view";
@import "lib/layout/fixed-ratio";
@import "lib/layout/capped-width"; // Depends on: unit-tools, breakpoints
@import "lib/layout/notation-view";

// COMPONENTS

@import "lib/components/type"; // Depends on: breakpoints, tools
@import "lib/components/headings";
@import "lib/components/forms"; // Depends on: breakpoint
@import "lib/components/counters";

@import "lib/layout/layout";

// TODO => move this code into dedicated components

@import "lib/components/login";
@import "~leaflet/dist/leaflet.css";
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
