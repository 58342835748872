/*******************************************************************************
 * LAYOUT
 * Path: lib/layout/_layout.scss
 ******************************************************************************/

// Box model

*,
*::before,
*::after {
    box-sizing: border-box;
}

// APP

html {
    overflow: hidden;
}

#app {
    overflow: hidden;
}

// IMG

img {
    display: block;
    max-width: 100%;
}

// MENU

.menu {
    @include reset-list();
}

// TAGS

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: $gutter-eighth;
}

// KEY VALUES

.key-values {
    .key-value {
        &.key-value--table {
            &:first-child {
                .key-value-label {
                    border-top: 1px solid $color-gray-lighter;
                }
            }
        }
    }
}

.key-values--page-bottom {
    .key-value {
        &.key-value--table {
            &:last-child {
                .key-value-label {
                    border-bottom-left-radius: $border-radius-base;
                }
                .key-value-value {
                    border-bottom-right-radius: $border-radius-base;
                    border-bottom: none;
                }
            }
        }
    }
}

.key-values--footer-bottom {
    .key-value {
        &.key-value--table {
            &:last-child {
                .key-value-value {
                    border-bottom: none;
                }
            }
        }
    }
}

// BUTTON BARS

.btn-bar {
    display: flex;
    justify-content: flex-end;
    gap: $gutter-half;
    @include v-padding($gutter-half)
}

.btn-bar--right {
    justify-content: flex-end;
}

.btn-bar--sub-h2 {
    border-top: 1px solid $color-gray-lightest;
    margin-top: -$gutter;
}

// CONTAINER

@mixin container() {
    @include h-margin($gutter-half);

    @include bp($breakpoint-sidebar-compact) {
        @include h-margin($gutter);
    }

    // @include bp($breakpoint-sidebar-full) {
    //   @include h-margin($gutter-and-half);
    // }
}

// CUSTOM SCROLLBAR

@mixin custom-scrollbar($color: $color-primary) {
    scrollbar-width: thin;
    scrollbar-color: $color $color-gray-lightest;
    scrollbar-gutter: stable; // Prevents unneeded layout changes as the content growing

    .widget--color-primary & {
        scrollbar-color: white $color;
    }

    &::-webkit-scrollbar {
        width: 0.6rem;
        height: 0.6rem;

        background-color: $color-gray-lightest;

        .widget--color-primary & {
            background-color: $color-primary;
        }
    }
    /* Add a thumb */
    &::-webkit-scrollbar-thumb {
        background-color: $color;
        border-radius: 3px;
  
        .widget--color-primary & {
            background-color: white;
        }
    }
  }
  
  