// PROTOCOL COUNTERS
.protocol-counters {
    border-top: 1px solid $color-gray-lighter;
    border-bottom: 1px solid $color-gray-lighter;
    @include v-padding($gutter-quarter);
    margin-bottom: $gutter-double;

    @include bp(sm) {
        display: flex;
        gap: $gutter-half;

        > * {
            flex-basis: 50%;
        }
    }
}

.protocol-counter {
    display: flex;
    align-items: center;
    gap: $gutter-half;

    &:first-child {
        margin-bottom: $gutter-quarter;

        @include bp(sm) {
            margin-bottom: 0;
        }
    }
}

.protocol-counter-value {
    @include size(6.6rem);
    background-color: $color-gray;
    color: white;
    font-size: $font-size-biggest;
    font-weight: $font-weight-semibold;
    text-align: center;
    line-height: 6.6rem;
}

.protocol-counter-label {
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $color-gray-dark;
}

.button-wrapper {
    display: flex;
    gap: $gutter-half;
    padding-top: $gutter-half;
    padding-bottom: $gutter;
    border-top: 1px solid $color-gray-lightest;
    margin-top: -$gutter;
}

// TABLE INDEX COUNTERS

$table-index-size: 2.4rem;

.table-index {
    @include size($table-index-size);
    color: white;
    background-color: $color-primary-dark;
    text-align: center;
    line-height: $table-index-size;
    @include h-margin(auto);
    border-radius: 50%;
    font-weight: $font-weight-normal;
}
  
.table-index--optional {
    background-color: $color-gray-light;
}
  
  