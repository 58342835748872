/*******************************************************************************
 * VARIABLES
 * Path: _variables.scss
 ******************************************************************************/

// Global class generating
$generate-classes: true;

////////////////////////////////////////////////////////////////////////////////
// LAYOUT

// UNIT TOOLS

$browser-fontsize-base: 14 !default; // Default browser font-size in px (unitless)
$pixels-per-rem: 10 !default; // Root font-size in px (unitless)

// GUTTER

$gutter: 3.2rem !default; // Gutter base size

// [Early core module @import]
// This allows to use the computed gutter variables in the following declarations
@import "lib/base/gutter";

// BREAKPOINTS

$generate-breakpoints-classes: true !default;

// List of breakpoint names and sizes

// Common screen sizes, in em unit (1em = 16px, browser default font-size)
// 'xxxs':  15em,    //  240 px (----) *

// 'xxs':   20em,    //  320 px (+33%)
// 'xs':    30em,    //  480 px (+50%) * (x2)
// 's':     40em,    //  640 px (+33%)

// 'sm':    48em,    //  768 px (+20%)
// 'md':    60em,    //  960 px (+25%) * (x2)
// 'lg':    75em,    // 1200 px (+25%)

// 'xl':    90em,    // 1440 px (+20%)
// 'xxl':   100em,   // 1600 px (----) (+33%)
// 'xxxl':  120em    // 1920 px (+33%) * (x2)

// 'xw':    150em    // 2400 px (+25%)
// 'xxw':   200em    // 3200 px (+33%)
// 'xxxw':  240em    // 3840 px (+20%) * (x2) (4K)

$breakpoints: (
    "xxs":  20em,   // 320 px
    "xs":   30em,   // 480 px
    "sm":   48em,   // 768 px
    "md":   60em,   // 960 px
    "lg":   75em,   // 1200 px
    "xl":   100em,  // 1440 px
    "xxl":  140em,  // 1960 px
) !default;

// The breakpoint at which starts the desktop layout
$bp-expanded: "md" !default;
// The breakpoint at which the desktop menu is shown
$bp-expanded-menu: "md" !default;
// The breakpoint at which the desktop menu is shown
$bp-expanded-container: "md" !default;

// GRID

$generate-grid-classes: true !default;

$n-children-max: 12 !default; // Max number of columns

// CONTAINERS

$generate-containers-classes: true !default;

// TOOLS

$generate-tools-padding-classes: true !default;
$generate-tools-margin-classes: true !default;
$generate-tools-text-classes: true !default;

////////////////////////////////////////////////////////////////////////////////
// COLORS

$generate-color-classes: true;

// COLOR PALETTE

// Base palette
$color-primary-darkest: #042909;
$color-primary-darker: #0a3410;
$color-primary-dark: #185020;
$color-primary-darky: #0f621b;
$color-primary: #267732;
$color-primary-lighty: #3aab3f;
$color-primary-light: #62df67;
$color-primary-lighter: #75b57e;
$color-primary-lightest: #a7d1ad;


$color-secondary: #3993ee !default;
$color-accent: #ff5706 !default;

$color-gray-darkest: #222222;
$color-gray-darker: #333333;
$color-gray-dark: #4a4a4a;
$color-gray-darky: #717171;
$color-gray: #979797;
$color-gray-lighty: #a6a6a6;
$color-gray-light: #bdbdbd;
$color-gray-lighter: #e0e0e0;
$color-gray-lightest: #ececec;
$color-gray-lightestest: #f6f6f6;

$color-gray-blueish: #e9ecea;

// "Universal" colors
$color-error: #EB5757 !default;
$color-warning: #F2994A !default;
$color-info: #516FC5 !default;
$color-success: #3AAB3F !default;

// Material palette (sort of)   
$color-material-red: #ff643e;
$color-material-pink: #ff8679;
$color-material-purple: #854B5A;
$color-material-deeppurple: #551A29;
$color-material-indigo: #484B7E;
$color-material-blue: #516FC5;
$color-material-lightblue: #6E93FD;
$color-material-cyan: #36CEC5;
$color-material-teal: #3B6A64;
$color-material-green: #019D5C;
$color-material-lightgreen: #89B03D;
$color-material-lime: #C5D8A1;
$color-material-yellow: #FFE804;
$color-material-amber: #FBD002;
$color-material-orange: #FF931C;
$color-material-deeporange: #AD6556;
$color-material-brown: #978A5D;
$color-material-grey: #BEBEBE;
$color-material-bluegrey: #A9B8E3;

// Micro-parcel background colors (x36)
$color-micro-parcel-background-0: #78D091;
$color-micro-parcel-background-1: #EFDF52;
$color-micro-parcel-background-2: #9E91E9;
$color-micro-parcel-background-3: #EF8346;
$color-micro-parcel-background-4: #7EADD9;
$color-micro-parcel-background-5: #FCAAEF;
$color-micro-parcel-background-6: #7ED9D9;
$color-micro-parcel-background-7: #F1BA7A;
$color-micro-parcel-background-8: #E86988;
$color-micro-parcel-background-9: #AABCFC;
$color-micro-parcel-background-10: #FD4747;
$color-micro-parcel-background-11: #ACE069;
$color-micro-parcel-background-12: #FDABBE;
$color-micro-parcel-background-13: #6BCDE2;
$color-micro-parcel-background-14: #A8C678;
$color-micro-parcel-background-15: #D69255;
$color-micro-parcel-background-16: #54E0FF;
$color-micro-parcel-background-17: #C4BBFB;
$color-micro-parcel-background-18: #74CCAC;
$color-micro-parcel-background-19: #C979D6;
$color-micro-parcel-background-20: #FFC700;
$color-micro-parcel-background-21: #D7E069;
$color-micro-parcel-background-22: #B395F2;
$color-micro-parcel-background-23: #CCFBBB;
$color-micro-parcel-background-24: #A7FFFF;
$color-micro-parcel-background-25: #EBB1B1;
$color-micro-parcel-background-26: #78C6C1;
$color-micro-parcel-background-27: #DA7E7E;
$color-micro-parcel-background-28: #A9A8CA;
$color-micro-parcel-background-29: #BD8E8E;
$color-micro-parcel-background-30: #99CBF9;
$color-micro-parcel-background-31: #EB7474;
$color-micro-parcel-background-32: #50FDFD;
$color-micro-parcel-background-33: #DA7E7E;
$color-micro-parcel-background-34: #D0B2FF;
$color-micro-parcel-background-35: #C4D26D;

// Micro-parcel repetition colors (x9)
$color-micro-parcel-repetition-0: #4B6840;
$color-micro-parcel-repetition-1: #700707;
$color-micro-parcel-repetition-2: #584979;
$color-micro-parcel-repetition-3: #574242;
$color-micro-parcel-repetition-4: #066F6F;
$color-micro-parcel-repetition-5: #3F5AB9;
$color-micro-parcel-repetition-6: #75096A;
$color-micro-parcel-repetition-7: #0F5139;
$color-micro-parcel-repetition-8: #7C2CFD;

// Protocol colors
$color-protocol-details: $color-primary;
$color-protocol-versions: #1db0a8;
$color-protocol-sharing: #408bd1;
$color-protocol-files: #89b03d;
$color-protocol-grouping: #6A75EB;

// Navigation dropdown colors
$color-navdropdown-1: $color-primary;
$color-navdropdown-2: #1db0a8;
$color-navdropdown-3: #408bd1;
$color-navdropdown-4: #89b03d;
$color-navdropdown-5: #FF931C;
$color-navdropdown-6: #978A5D;

// Notation status colors
$color-notation-in-progress: #2F9DB6;
$color-notation-in-progress-light: #59E1FF;
$color-notation-wait-synchro: #CD5410;
$color-notation-wait-synchro-light: #FF7B31;
$color-notation-error-synchro: #B42C2C;
$color-notation-error-synchro-light: #FF1313;

// Grouping colors
$color-grouping-active: #8BF478;
$color-grouping-active-dark: mix(black, $color-grouping-active, 20%);
$color-grouping-inactive: #F78363;
$color-grouping-inactive-dark: mix(black, $color-grouping-inactive, 20%);

// Shading factors
$color-variation-ratio-less: 20% !default;
$color-variation-ratio-base: 45% !default;
$color-variation-ratio-more: 65% !default;
$color-variation-ratio-most: 85% !default;

// [Early core module @import]
// This allows to use the computed color variables in the following code
@import "lib/base/colors";

// ELEMENTS COLORS

// Body
$body-color-base: $color-gray-darker !default;
$body-bg-color-base: white !default;

// Links
$link-color: $color-primary !default;
$link-color-active: $color-primary-darker !default;

////////////////////////////////////////////////////////////////////////////////
// TYPOGRAPHY

// BASE

// Font family
$font-family-sans-serif: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: "Lora", Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;

$font-family-base: $font-family-sans-serif !default;

// Font size
$font-size-base: 1.4rem !default;

// 24/21/18/16/14/12

$font-size-smaller: 1rem !default;
$font-size-small: 1.2rem !default;
$font-size-big: 1.6rem !default;
$font-size-bigger: 1.8rem !default;
$font-size-biggest: 2.1rem !default;
$font-size-large: 2.4rem !default;
$font-size-larger: 2.8rem !default;
$font-size-largest: 3.2rem !default;

// Line height
$line-height-base: 1.5 !default;
$line-height-small: 1.25 !default;
$line-height-smaller: 1.125 !default;
$line-height-smallest: 1 !default;

// Font weight
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: normal !default; // 400
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;

// GLOBAL ZOOM

// Pixel per rem, for each breakpoints
// Aka "global zoom"
$breakpoints-pixels-per-rem: (
    "xxs": 9,
    "xs": 10,
    "sm": 10,
    "md": 10,
    "lg": 11,
    "xl": 12,
) !default;

$use-global-zoom: false !default;

// HEADINGS

$generate-headings-classes: true !default;

$headings: (
    "default": (
        "font-size": $font-size-base,
        "font-weight": $font-weight-bold,
        "font-family": $font-family-sans-serif,
        "text-transform": none,
        "line-height": $line-height-small,
        "margin-bottom": $gutter-half,
    ),
    "h1": (
        "font-size": $font-size-large,
        "font-weight": $font-weight-bold,
        "line-height": $line-height-smallest,
        "margin-bottom": $gutter-and-half,
    ),
    "h2": (
        "font-size": $font-size-biggest,
        "font-weight": $font-weight-bold,
        "line-height": $line-height-smaller,
        "margin-bottom": $gutter,
        "text-transform": uppercase,
    ),
    "h3": (
        "font-size": $font-size-bigger,
        "font-weight": $font-weight-bold,
        "line-height": $line-height-smaller,
    ),
    "h4": (
        "font-size": $font-size-big,
        "font-weight": $font-weight-semibold,
        "text-transform": uppercase,
    ),
    "h5": (
        "font-size": $font-size-base,
        "font-weight": $font-weight-bold,
    ),
    "h6": (
        "font-size": $font-size-small,
        "font-weight": $font-weight-bold,
    )
) !default;

// Border radius
$border-radius-base: 8px !default;
$border-radius-small: $border-radius-base / 2 !default;
$border-radius-big: $border-radius-base * 2 !default;

// FORMS

$input-bg: $body-bg-color-base !default;
$input-bg-strong-focus: $color-gray-lightest !default;
$input-color: $body-color-base !default;
$input-color-placeholder: $color-gray !default;

$input-border-color: $color-gray-light !default;
$input-border-color-hover: $color-gray !default;
$input-border-color-filled: $color-gray-dark !default;
$input-border-radius: $border-radius-base !default;
$input-height-base: 4.0rem !default;

$input-size-radiobox: 2rem !default;
$input-size-radiobox-inner: 1.2rem !default;
$input-color-radiobox-inner: $color-primary-lighty !default;

$input-width-gutter-label-inline: $gutter-half !default;
$input-width-gutter-label-radiobox: $gutter-quarter !default; // Between checkbox/radio and their labels
$input-width-gutter-block-inline: $gutter !default;
$input-width-gutter-btn-inline: $gutter-quarter !default;

$input-color-focus: $color-info !default;

// ICONS

$generate-icons-classes: true !default;

$icon-sizes: (
    "xs": $font-size-smaller,
    "sm": $font-size-small,
    "md": $font-size-base,
    "lg": $font-size-big,
    "xl": $font-size-bigger,
    "xxl": $font-size-biggest,
) !default;

// BUTTONS

$generate-buttons-classes: true !default;

$btn-padding: 1.2rem; // Magic value!

$btn-font-weight: $font-weight-semibold !default;
$btn-text-transform: none !default;
$btn-border-radius: $border-radius-base !default;

$btn-border-size-hollow: 2px !default;
$btn-border-size-fat: 0.4rem !default;
$btn-shadow-active: 1 !default; // Refers to @mixing shadow() parameter (from 0 to 5)
$btn-color-active: true !default; // Lighter background on hover and focus

// CODE

$code-border-radius: $border-radius-base !default;

// Z-INDEX

$z-index-overlay: 10000;
$z-index-sticky: 20000;
$z-index-panel: 30000;
$z-index-modal: 40000;
$z-index-debug: 100000;

// HEADER AND FOOTER

// Header
$pre-header-v-padding: $gutter-eighth !default;
$pre-header-bg-color: $color-gray-darker !default;
$pre-header-color: white !default;

$header-v-padding: $gutter-half !default;
$header-bg-color: $color-gray-darker !default;
$header-color: white !default;

$post-header-v-padding: $gutter-eighth !default;
$post-header-bg-color: $color-gray-lightest !default;
$post-header-color: $color-gray-dark !default;

// Footer
$pre-footer-v-padding: $gutter-eighth !default;
$pre-footer-bg-color: $color-gray-lightest !default;
$pre-footer-color: $color-gray-dark !default;

$footer-v-padding: $gutter !default;
$footer-bg-color: $color-gray-darker !default;
$footer-color: white !default;

$post-footer-v-padding: $gutter !default;
$post-footer-bg-color: $color-gray-darker !default;
$post-footer-color: white !default;

// LOGOS

$generate-logos-classes: true !default;

$height-logo-mobile: 3.2rem;
$height-logo-tablet: 4.0rem;
$height-logo-desktop: 7.6rem;

$bp-logo-tablet: 'xs';
$bp-logo-desktop: 'md';

// MENUS

$generate-menus-classes: true !default;

// NAVBAR

$generate-navbars-classes: true !default;

// OVERLAY

$generate-overlay-classes: true !default;

// MODALS

$generate-modals-classes: true !default;

// SHADOWS

$generate-shadow-classes: true;

// CAPPED WIDTH

$generate-capped-width-classes: true !default;

// DEBUG

$debug-zone-background: rgba(0, 0, 0, 0.75);
$debug-zone-text-color: chartreuse;

////////////////////////////////////////////////////////////
// APP

$touch-target-size-base: 4rem;

$icon-size-xxs: 1.2rem;
$icon-size-xs: 1.6rem;
$icon-size-sm: 2rem;
$icon-size-base: 2.4rem;
$icon-size-lg: 2.8rem;
$icon-size-xl: 3.2rem;
$icon-size-xxl: 4rem;

$hamburger-size: $icon-size-base;

// Custom easings

$ease-bump: cubic-bezier(0.985, -0.33, 0.01, 1.32);
$ease-in-out: cubic-bezier(1, 0.005, 0.02, 0.995);

// HEADER

$header-height-mobile: 8rem;
$header-height-tablet: 6rem;
// $header-height-tablet: $touch-target-size-base + $gutter;
$header-height-desktop: $touch-target-size-base + $gutter;

$sidebar-width-mobile: 32rem; // Mobile menu panel width
$sidebar-width-tablet: 6rem;
// $sidebar-width-tablet: $touch-target-size-base + $gutter;
$sidebar-width-tablet-expanded: $sidebar-width-mobile;
$sidebar-width-desktop: 26rem;

$breakpoint-sidebar-compact: "md"; // Compact sidebar, with only items icons shown
$breakpoint-sidebar-full: "lg"; // Sidebar with icons and text on desktop
$breakpoint-user-menu-with-name: "xs"; // Display user name in user menu toggle button
$breakpoint-content-with-padding: "xs"; // "Double" padding, both on `.site-content` and `.site-main-content`
$breakpoint-view-header-inline: 'md'; // View header is stacked below this breakpoint

$color-gray-outside-content: $color-gray-blueish;

// Navbar menu

$navbar-menu-item-height-mobile: 6rem;
