/*******************************************************************************
 * CAPPED WIDTH
 * Path: lib/layout/_capped-width.scss
 ******************************************************************************/

////////////////////////////////////////////////////////////////////////////////
// CLASSES

@if $generate_classes and $generate-capped-width-classes {

    @each $breakpoint-name, $breakpoint-width in $breakpoints {
        .max-width-#{$breakpoint-name} {
            max-width: em2rem($breakpoint-width);
        }
    }
}
