.has-notation-layout {
    .view {
        max-width: 73.5rem;
        @include h-margin(auto);
        height: 100%;
        display: flex;
        flex-direction: column;

        @include bp("lg") {
            position: relative; // For prev/next buttons
        }
    }
    .view-body {
        flex-grow: 1;
        background-color: white;
        border-radius: $border-radius-base;
        margin-top: -$gutter-quarter; // Hack (round corner)
        overflow-y: auto;
        padding: $gutter $gutter-double;
        @include bp("sm") {
            @include h-padding($gutter-and-half);
        }
    }

    .view-header__header {
        gap: $gutter-half;

        > * {
            flex-grow: 1;
        }
    }

    .view-footer {
        background-color: white;
        margin-top: $gutter-half;
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    
        @include bp("xs") {
            margin-bottom: -$gutter-half;
        }
    
        @include bp("md") {
            margin-bottom: -$gutter;
        }
    
        @include bp("lg") {
            margin-bottom: -$gutter-and-half;
        }
    }
}

.title-with-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
  
.notation-back-btn {
    justify-content: flex-start;
}

.view-header__notation-tabs {
    padding-top: $gutter-quarter;
}

// Notation tabs

.notation-tabs {
    display: flex;
    gap: $gutter-half;
}

.notation-tab {
    display: block;
    flex-grow: 1;
    max-width: 50%;
    text-align: center;
    padding: $gutter-quarter;
    padding-bottom: $gutter-half; // Hack (round corner)
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;

    > span {
        text-transform: uppercase;
        font-size: $font-size-bigger;
        font-weight: $font-weight-black;
        color: $color-gray;
        transition: all 0.25s;
    }

    &.router-link-exact-active,
    &.active {
        background-color: white;

        > span {
            color: $color-primary-lighty;
        }
    }

    @include hocus() {
        > span {
            color: $color-primary-lighty;
        }
    }
}

// Previous and next buttons

$notation-btn-size: 12.4rem;

.notation-btn {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    @include size($notation-btn-size);
    color: $color-primary-lighty;

    ::v-deep(.icon) {
        @include size(6.2rem);
    }

    @include hocus() {
        @include shadow(2);
        color: $color-primary !important;
    }

    &:active {
        @include shadow(0);
    }
}

.notation-btn-help-text--disabled {
    opacity: 0.5;
}

.notation-btn--previous {
    left: -($notation-btn-size / 2);
    justify-content: right;

    @include bp("lg") {
        left: -($notation-btn-size + $gutter);
        justify-content: center;
    }
}

.notation-btn--next {
    right: -($notation-btn-size / 2);
    justify-content: left;

    @include bp("lg") {
        right: -($notation-btn-size + $gutter);
        justify-content: center;
    }
}

.keycap {
    display: inline-block;
    padding: 0 $gutter-eighth;
    background-color: $color-gray-lightest;
    border: 1px solid $color-gray;
    border-radius: $border-radius-small;
}

// Footer global progress bar
.notation-progress {
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: $gutter-half;
}

.notation-progress-label {
    color: $color-gray;
    display: none;

    @include bp("xs") {
        display: initial;
    }
}

.notation-progress-bar {
    position: relative;
    flex-grow: 1;
    height: 0.5rem;
    background-color: $color-gray-light;
    border-radius: 0.25rem;
}

.notation-progress-bar-line {
    height: 0.5rem;
    background-color: $color-primary-lighty;
    border-radius: 0.25rem;
}

.notation-progress-bar-number {
    position: absolute;
    bottom: $gutter-eighth;
    transform: translateX(-50%);
    font-size: $font-size-bigger;
    color: $color-primary-lighty;
    font-weight: $font-weight-black;
}

// Notation view body content

.notation-view-body-header {
    display: flex;
    gap: $gutter;
    align-items: flex-start;
    margin-bottom: $gutter;
}

.notation-index {
    flex-shrink: 0;
    position: relative;
    @include size(9.6rem);
    border-radius: 50%;
    background-color: $color-primary-lighty;
    font-size: $font-size-biggest;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: $gutter-quarter;
    justify-content: center;

    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 6.8rem;
        background-color: currentColor;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.notation-index__current {
    font-weight: $font-weight-black;
}

.notation-index__total {
    font-weight: $font-weight-light;
}

.notation-metas {
}

.notation-meta {
    display: flex;
    gap: $gutter-quarter;
    justify-content: space-between;
    align-items: baseline;
    padding: ($gutter-eighth / 2) $gutter-eighth;
    margin-bottom: $gutter-eighth;
    background-color: $color-gray-lightest;
    border-radius: $border-radius-small;
}

.notation-meta__label {
    color: $color-gray;
}

.notation-meta__value {
    text-align: right;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
}

.notation-micro-parcel-coordinates {
    margin-left: auto;
    text-align: right;
}

.micro-parcel-coordinates__label {
    font-size: $font-size-big;
    line-height: $line-height-small;
}

.micro-parcel-coordinates__value {
    font-size: 4.8rem;
    font-weight: $font-weight-black;
    color: $color-primary-lighty;
}

// Main content

.notation-view-body-body {
    padding: $gutter;
    border-radius: $border-radius-base;
    @include shadow(1);
}

.notation-view-body-body-header {
    display: flex;
    gap: $gutter;
    align-items: flex-end;
    margin-bottom: $gutter;
    margin-top: -$gutter-half;
}

.notation-view-body-body-body + .notation-view-body-body-header {
    margin-top: $gutter;
}

.notation-form-title {
    margin-right: auto;
}

.notation-form-variable-name {
    font-size: $font-size-biggest;
    font-weight: $font-weight-black;
    line-height: $line-height-small;
    margin-bottom: $gutter-eighth;
}

.notation-form-target-name {
    font-size: $font-size-big;
    color: $color-gray;
    line-height: $line-height-small;

    > span {
        font-weight: $font-weight-black;
        color: $body-color-base;
        text-transform: uppercase;
    }
}

// Notation "real-time" stats

.notation-form-stats {
    display: flex;
    gap: $gutter-half;
}

.notation-form-stat {
}

.notation-form-stat__label {
    margin-bottom: $gutter-eighth;
    text-align: center;
    color: $color-gray;
}

.notation-form-stat__value {
    min-width: 8.4rem;
    padding: $gutter-quarter;
    text-align: right;
    background-color: $color-primary-lightest;
    border-radius: $border-radius-small;
    font-weight: $font-weight-semibold;
}
